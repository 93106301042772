.change-status-button {
  width: 100%;
}

.ant-upload-list-item {
  position: absolute !important;

}

.ant-table-content {
  overflow: auto hidden;

}
