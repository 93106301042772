body {
  margin: 0;

}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.signOut:hover {
  color: #1890ff !important;
  cursor: pointer;
}

.signOut svg {
  margin-bottom: 1px;
}

h1 {
  margin-top: 0;
}

.action-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px -5px 0;

}

.actionSelenium .action-list{
  display: flex;
  flex-direction: column;
}

.action-button {
  flex: 1 0 auto;
  margin: 5px 0 5px 0;

}

.delete-popconfirm {
  flex: 1 0 auto;

}

.delete-popconfirm > button {
  width: 100%;

}

.menu-icon {
	padding-right: 8px;

}

td.identityCodeSelenium, td.idSelenium, td.skuSelenium {
  max-width: 150px;

}

td.descriptionSelenium {
  max-width: 300px;

}

td.nameSelenium, td.typeSelenium, td.batchNameSelenium {
  max-width: 220px;

}

td.statusChangeAtSelenium {
  max-width: 440px;

}

.ant-descriptions-item-content {
  word-break: break-word;

}

.ant-btn-link:hover {
  cursor: pointer;
}
